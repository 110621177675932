.PipelineMaintenanceBox {

    label{
        // width: 8rem;
        // margin-left: 0px;
        width: 140px;
    }
    
    .textAreaPipelineArea{
        // height: 95px;
        // width: 390px;
        // max-width: 1000px;
        // margin-right: 20px;
        height: 95px;
    //    width: 428px;
        max-width: 1000px;
        margin-right: 10px;
        //width: 400px;
        width: 400px;
    
    }
    
    .innerBoxes {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        box-shadow: 0 1px 0 0 #e8e8e8;
        border: 1px solid rgba(151, 151, 151, 0.23);
        border-bottom: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin: 10px;
      }
    
    input[disabled] {
        color: #DCDAD1;
    }
    
    textArea[disabled] {
        color: #DCDAD1;
    }
    
    .input{
        position: relative;
        width: 163px !important;
    }
    .select {
        background-color: #ffffff;
        color: #000000;
        font-size: 14px;
        height: 35px;
        min-width: 180px;
        max-width: 220px;
        border-radius: 5px;
        border: 1px solid  #a3d0e4;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;;
        margin-left: 5px;
        appearance: none;
        background-image: linear-gradient(50deg, transparent 50%,  #333333 50%),
                        linear-gradient(130deg,  #333333 50%, transparent 50%),
                        linear-gradient(to right, transparent, transparent);
        background-position:
            calc(100% - 15px) calc(1em + 1px),
            calc(100% - 10px) calc(1em + 1px),
            100% 0;
        background-size:
            5px 5px,
            5px 5px,
            2.5em 2.5em;
        background-repeat: no-repeat;
    }
    // .standard_label_style {
    //     color: #333333 !important;
    //     font-weight: bold;
    //     margin-left: 20px;
    //     font-size: 14px;
    // }
    
    .status-block{
        display: inline-flex;
        // label{
        //     width:50px
        // }
    }
    
    input:read-only {
        background: lightgray;
    }
    
    textArea[disabled] {
        color: #DCDAD1;
    }
    
    
    textArea:read-only {
        background: lightgray;
    }
    
    input[type=radio] {
            position: relative;
            margin-top: 5px;
            margin-bottom: 25px;
    }
    
    .col{
        display: flex;
        margin-top: 20px;
        // max-width: 325px;
        //max-width: 317px;
        margin-left: -5px;
    }
    
    .colPipelineArea{
        max-width: unset;
    }
    
    
    .rightFlexGrid{
        display: flex;
        justify-content: flex-end;
        right: 157px;
        position: relative;
        top: 34px;
        margin-top: -35px;
    }
    
    .rightFlex{
        display: flex;
        justify-content: flex-end;
        margin-right: 40px;
        position: inherit;
        top: 0px;
        margin-top: 0px;
    }
    
    
    .rightFlexBottom{
        display: flex;
        justify-content: flex-end;
        right: 20px;
        position: relative;
      //  margin-top: -25px;
    }
    
    .textComments{
        width: 92%;
        max-width: unset;
        margin-right: 20px;
    }

    @media screen and (max-width: 1223px) {
        .status-block{
            display: inline-flex;
            label{
                width:103px
            }
        }
        .textComments {
            width: 90%;
        }
    }
    @media screen and (max-width: 580px) {
        .textAreaPipelineArea {
            height: 95px;
            width: 283px;
        }
        .rightFlex{
            right: 5px;
            top: 0px;
            margin-top: 0px;
        }
        .textComments {
            width: 70%;
        }
    }
    // .row{
    //     margin-top:20px;
    // }
    
        .row{
            margin-left: 5px;
        }
    
        .data-grid {
            > div:first-child {
                height: 60vh !important;
            }
        }
        
    }