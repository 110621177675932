.createTariffLogBox {
    margin-top: 20px;
    .rotatedText {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        border: 1px solid black;
        padding: 10px;
        margin-right: 20px;
        text-align: center;
    }
    .flex {
        display: flex;
    }

    .screenHeader {
        color: #333333;
        font-weight: bold;
        background-color: #d9ecf5;
        display: flex;
        flex-direction: row;
        height: 40px;
        border: 1px solid #a3d0e4;
        align-items: center;
        margin-bottom: 10px;
    }
    // .screen_title {
    //     margin-left: 10px;
    // }
    // .selectContainer {
    //     justify-content: left;
    // }
    .predictiveSelect {
        display: block;
        padding: 5px;
        border: 1px solid black;
    }
    .predictiveSelectContainer{
        background: lightblue;
        position: absolute;
        z-index: 1;
        height: 143px;
        overflow-y: scroll;
        width: 180px;
    }
    .data-grid {
        > div:first-child {
            height: 50vh !important;
            width: 100%;
            margin-right: 20px;
        }
    }    
    .ag-theme-balham {
        margin-right: 5px;
    }
    .baseTariff{
        .data-grid {
            > div:first-child {
                height: 50vh !important;
                width: 100%
            }
        }
    }
    .page-size-div {
        border: none;
    }
label{
    // width: 8rem;
    margin-left: 0px;
    //width: 103px;
    width: 106px;
}

.textAreaPipelineArea{
    height: 95px;
    max-width: 1000px;
    margin-right: 10px;
    //width: 325px;
    margin-left: 5px;
    width: 300px;
}


input[disabled] {
    color: #DCDAD1;
    border: 1px solid #d3d3d3;
}

input:read-only {
    background: lightgray;
    color: #000000;
}

textArea[disabled] {
    color: #DCDAD1;
}


textArea:read-only {
    background: lightgray;
}

.input{
    position: relative;
    width: 200px !important;
}

.agencyInput {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 35px;
    min-width: 180px;
    max-width: 440px;
    border-radius: 5px;
    border: 1px solid   #a3d0e4;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    width: 300px !important;
}

.logStatus {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 35px;
    min-width: 180px;
    max-width: 440px;
    border-radius: 5px;
    border: 1px solid   #a3d0e4;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;;
    margin-left: 10px;
    position: relative;
    width: 240px !important;
}
 

.versionInput{
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 35px;
    width: 50px;
    border-radius: 5px;
    border: 1px solid   #a3d0e4;
    font-family: 'Arial';
    margin-left: 10px;
}
.mainVersionInput{
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 35px;
    width: 120px;
    border-radius: 5px;
    border: 1px solid   #a3d0e4;
    font-family: 'Arial';
    margin-left: 10px;
}
.VmacsTariffInput{
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 35px;
    width: 300px;
    border-radius: 5px;
    border: 1px solid   #a3d0e4;
    font-family: 'Arial';
    margin-left: 10px;
}
.select {
    // // max-width: 200px;
    // width: 177px !important;
    // margin-left: 0px;
    
    width: 200px !important;
    margin-left: 10px;
    height: 40px;

}

.status-block{
    display: inline-flex;
}

input[type=radio] {
        position: relative;
        margin-top: 5px;
        margin-bottom: 25px;
}

.col{
    display: flex;
    margin-top: 10px;
    // max-width: 325px;
    max-width: unset;
    //margin-left: -5px;
}

.tariffTeam{
    margin-bottom: 50px;
    .col {
        max-width: unset;
    }
    
    label{
        width: 137px;
    }
    .descriptionInputBox{
        // width: 70% !important;
        height: 200%;
        max-width: unset;
        min-width: 300px;
    }
    .description {
        max-height: 120%;
    }
}

.colPipelineArea{
    max-width: 464px;;
}

.attachmentsGrid {
    display: inline-flex;
}

.rightFlexBottom{
    display: flex;
    justify-content: flex-end;
    right: 20px;
    position: relative;
  //  margin-top: -25px;
}

.textComments{
    width: 92%;
    max-width: unset;
    margin-right: 20px;
}

@media screen and (max-width: 1223px) {
    // .status-block{
    //     display: inline-flex;
    //     label{
    //         width:103px
    //     }
    // }
    .tariffTeam{
        .descriptionInputBox{
            height: unset;
            min-width: 300px;
        }
    }
    .textComments {
        width: 90%;
    }
}
@media screen and (max-width: 580px) {
    .rightFlex{
        right: 5px;
        top: 0px;
        margin-top: 0px;
    }
    .textComments {
        width: 70%;
    }
    .tariffTeam{
        .descriptionInputBox{
            height: unset;
            min-width: 300px;
        }
    }
}

// .inline{
//     // width: 100%;
//     // display: inline-flex;
// }


textArea {
    width: 95%;
    height: 100px;
    margin-left: 10px;
}

select:disabled {
    background: lightgray;
    color:#000000;
}

.flex{
    display: flex;
}

.spaceColumn {
    width: 450px;
}

}

.workflowDetails{
    margin-top: 10px;


    .selectContainer {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        border: 1px solid #a3d0e4;
    }

    .screenHeader {
        color: #333333;
        font-weight: bold;
        background-color: #d9ecf5;
        display: flex;
        flex-direction: row;
        height: 40px;
        border: 1px solid #a3d0e4;
        align-items: center;
        margin-bottom: 0px;
    }
    label {
        line-height: 2;
        vertical-align: top;
        min-width: 150px;
        margin-left: 20px;
        width: unset
    }
    // .inline{
    //     // width: 100%;
    //     // display: inline-flex;
    // }
    .multiSelectContainer {
        width: 100%;
        height: 200%;
        margin-left: 10px;
    }
    .multiselect{
        width:100%;
        min-width: 169px;
    }



    .mainContainerMultiselect{
        div:nth-child(2){
            width: 100%;
        }
    }

    textArea {
        width: 95%;
        height: 100px;
        margin-left: 10px;
    }

    .search_text_field {
        background-color: #ffffff;
        color: #000000;
        font-size: 14px;
        // width: 90%;
        height: 35px;
        min-width: 150px;
        max-width: unset;
        border-radius: 5px;
        border: 1px solid #a3d0e4;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        margin-left: 10px;
        margin-right: 10px;
        width: 58%;
    }

    .data-grid {
        > div:first-child {
            height: 50vh !important;
            width: 98%;
            min-height: 200px;
            min-width: 170px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    input:read-only, textArea:read-only, select:disabled {
        background: lightgray !important;
    }   

    .rightFlex {
        width: 50%;
        display: flex;
    }

    .rightFlexInner {
        justify-content: flex-end;
        display: flex;
        width: 100%;
    }

    .leftFlex {
        justify-content: flex-start;
        display: flex;
        margin-top: 10px;
        label{min-width: 120px;}
    }

    .rightFlexButtons {
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        display: flex;
        align-items: flex-end;
        // margin-left: 10px;
        margin-left: -10px;
    }

    .flex{
        display: flex;
    }

    .flexColumn{
        display:flex;
        flex-direction: column;
        height: 100%;
        label{min-width: 120px; width: unset}
    }

    .innerflexwidths{
        width: 50%
    }
    .datepicker {
        display: inline-flex;
        width: 80%;
    }

    .status-block{
        display: inline-flex;
    }

    input[type=radio] {
        margin: 0px 10px 0px 10px;
    }

    @media screen and (max-width: 980px) {
        .flex {
            flex-direction: column;
        }

        .custom-button {
            margin-right: 10px;
        }
        
        .innerflexwidths{
            width: 100%
        }

        button{
            height: 50px
        }

        .rightFlex{
            button{
                margin-right: 5px;
            }
        }

        textArea {
            width: 92%;
        }

        .rightFlex {
            width: 100%;
        }

        .rightFlexButtons {
            margin-left: 10px;
            padding-right: 5px;
        }

        .data-grid {
            > div:first-child {
                width: 98%;
            }
        }
    }
    @media screen and (max-width: 580px) {
        .data-grid {
            > div:first-child {
                width: 92%;
            }
        }
        
        textArea {
            width: 80%;
            margin-left: 0px;
        }

        .rightFlex{
            flex-wrap: wrap;
        }
    }
}