.TariffWorkflowCol {
  display: flex;
}
.TariffWorkflowLabel {
  margin-top: 4px;
}
.pmcTariffBox {
  .rotatedText {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    border: 1px solid black;
    padding: 10px;
    margin-right: 20px;
    text-align: center;
  }

  .react-datepicker-wrapper {
    border: 0;
    display: inline-block;
    padding: 0;
    width: 0%;
  }

  .select {
    height: 40px;
  }

  .flex {
    display: flex;
  }

  .screenHeader {
    color: #333333;
    font-weight: bold;
    background-color: #d9ecf5;
    display: flex;
    flex-direction: row;
    height: 40px;
    border: 1px solid #a3d0e4;
    align-items: center;
  }

  .screen_title {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #333333 !important;
    letter-spacing: 0.1px;
    line-height: 40px;
    margin-left: 10px;
    justify-content: left;
  }

  .selectContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid #a3d0e4;
    margin-bottom: 10px;
  }

  .tariffNumber {
    display: flex;
    position: relative;
    margin-bottom: 8px;
    color: #0c63e4;
    align-self: flex-start;
  }

  .screen_title_ID {
    margin-left: 10px;
    font-size: 16px;
    color: #0c63e4;
  }

  .data-grid {
    > div:first-child {
      height: 50vh !important;
      width: 100%;
      margin-right: 20px;
    }
  }

  .ag-theme-balham {
    margin-right: 5px;
  }

  .newTariff {
    .data-grid {
      > div:first-child {
        height: 50vh !important;
        width: 100%;
      }
    }
    .standard_label_style {
      color: #333333 !important;
      font-weight: bold;
      margin-left: 20px;
      font-size: 14px;
      width: 124px;
    }
    .multiselect {
      width: 220px;
      margin-left: 5px;
    }
    .multiselectProduct {
      width: 220px;
    }
    .react-datepicker-popper[data-placement^='bottom'] {
      padding-top: 10px;
      inset: -24px auto auto 10px !important;
    }
    .marginRight {
      margin-right: 4px;
    }
    .datepicker__holder {
      border: 1px solid #a3d0e4;
      width: 220px;
      margin-left: 0px;
    }
    .lastColInput {
      margin-left: 0px;
    }
    .input_box {
      width: 340px;
    }
  }

  .page-size-div {
    border: none;
  }

  .fees {
    .react-datepicker-wrapper {
        border: 0;
        display: inline-block;
        padding: 0;
        width: 100%;
      }
    .shrinkageLossAllowanceLabel {
      margin-right: 5px;
    }
    .standard_label_style {
      color: #333333 !important;
      font-weight: bold;
      margin-left: 20px;
      font-size: 14px;
      width: 160px;
    }
    .standard_label_style_note {
      color: red !important;
      font-weight: bold;
      margin-left: 20px;
      font-size: 14px;
      width: 364px;
      margin-top: 16px;
    }
    .escalationMonth {
      width: 202px;
      margin-left: 10px;
    }
    .primaryRateCheckbox {
      padding: 5px;
      width: 0px;
      position: relative;
      margin-right: -10px;
    }
    .total {
      margin-right: 150px;
    }
    .totalLable {
      font-size: 16px;
    }
    
  }

  .standard_label_style {
    color: #333333 !important;
    font-weight: bold;
    margin-left: 20px;
    font-size: 14px;
    width: 160px;
  }

  select[disabled],
  input:read-only {
    color: hsl(0, 0%, 60%);
    border: 1px solid #d3d3d3;
    background: #d3d3d3;
  }

  .disable_ms,
  #multiselect:disabled,
  #search_input:disabled {
    color: #333333;
    border: 1px solid #a3a2a2;
    background: #a3a2a2;
  }

  .css-16xfy0z-control {
    color: #333333;
    border: 1px solid #d3d3d3;
    background: #d3d3d3;
  }

  .input {
    position: relative;
    width: 202px !important;
  }
  .input_box {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 35px;
    width: 180px;
    border-radius: 5px;
    border: 1px solid #a3d0e4;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .small_search_text_field {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 35px;
    min-width: 60px;
    max-width: 100px;
    border-radius: 5px;
    border: 1px solid #a3d0e4;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin-left: 10px;
  }

  .mainDiv {
    display: flex;
    justify-content: end;
    margin-right: 32px;
  }

  .expanded_search_text_field {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 35px;
    width: 80%;
    border-radius: 5px;
    border: 1px solid #a3d0e4;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin-left: 8px;
    margin-top: 10px;
  }

  .expanded_text_field {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 35px;
    width: 342px;
    border-radius: 5px;
    border: 1px solid #a3d0e4;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .select_small {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 40px;
    width: 202px;
    border-radius: 5px;
    border: 1px solid #a3d0e4;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin-left: 5px;
    appearance: none;
    background-image: linear-gradient(50deg, transparent 50%, #333333 50%),
      linear-gradient(130deg, #333333 50%, transparent 50%),
      linear-gradient(to right, transparent, transparent);
    background-position: calc(100% - 15px) calc(1em + 1px), calc(100% - 10px) calc(1em + 1px),
      100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
  }

  .select {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 40px;
    min-width: 180px;
    max-width: 220px;
    border-radius: 5px;
    border: 1px solid #a3d0e4;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin-left: 5px;
    appearance: none;
    background-image: linear-gradient(50deg, transparent 50%, #333333 50%),
      linear-gradient(130deg, #333333 50%, transparent 50%),
      linear-gradient(to right, transparent, transparent);
    background-position: calc(100% - 15px) calc(1em + 1px), calc(100% - 10px) calc(1em + 1px),
      100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
  }

  .expanded_select {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 40px;
    width: 342px;
    border-radius: 5px;
    border: 1px solid #a3d0e4;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    appearance: none;
    background-image: linear-gradient(50deg, transparent 50%, #333333 50%),
      linear-gradient(130deg, #333333 50%, transparent 50%),
      linear-gradient(to right, transparent, transparent);
    background-position: calc(100% - 15px) calc(1em + 1px), calc(100% - 10px) calc(1em + 1px),
      100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
  }

  // .destination_expanded_select {
  //   background-color: #ffffff;
  //   color: #000000;
  //   font-size: 14px;
  //   height: 35px;
  //   width: 300px;
  //   border-radius: 5px;
  //   border: 1px solid #a3d0e4;
  //   font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  //   appearance: none;
  //   background-image: linear-gradient(50deg, transparent 50%, #333333 50%),
  //     linear-gradient(130deg, #333333 50%, transparent 50%),
  //     linear-gradient(to right, transparent, transparent);
  //   background-position: calc(100% - 15px) calc(1em + 1px), calc(100% - 10px) calc(1em + 1px),
  //     100% 0;
  //   background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  //   background-repeat: no-repeat;
  // }

  button[disabled] {
    background-color: #ccc;
    margin-left: 20px;
  }

  .multiSelectContainer {
    width: 89%;
  }

  .searchWrapper {
    border: 1px solid #a3d0e4;
  }

  .datepicker__holder {
    border: 1px solid #a3d0e4;
    width: 202px;
  }

  .descriptionInputBox {
    height: 200%;
    max-width: unset;
  }

  .status-block {
    display: inline-flex;
  }

  .col {
    display: flex;
    margin-top: 10px;
  }

  .notesGrid {
    display: inline-flex;
  }

  .attachmentsGrid {
    display: inline-flex;
  }

  .rightFlex {
    display: flex;
    justify-content: flex-end;
    right: 157px;
    position: relative;
    top: 34px;
    margin-top: -35px;
  }

  .rightFlexBottom {
    display: flex;
    justify-content: flex-end;
    right: 20px;
    position: relative;
    // margin-top: 25px;
  }

  .centerFlexBottom {
    display: flex;
    justify-content: center;
    right: 20px;
    position: relative;
    margin-top: 25px;
  }

  @media screen and (max-width: 1500px) {
    .newTariff {
      .productType {
        margin-left: 18px;
        margin-right: 2px;
      }
      .datepicker__holder {
        width: 180px;
      }
      .expanded_select {
        min-width: 180px;
        max-width: 180px;
      }
      .product {
        margin-left: 20px;
      }
      .uom {
        margin-left: 20px;
      }
      .expanded_text_field {
        width: 180px;
      }
      .multiselect {
        width: 180px;
        margin-left: 0px;
      }
      .operator {
        width: 180px;
        margin-left: 0px;
      }
      .destination_expanded_select {
        width: 180px;
      }
      .destination {
        width: 180px;
        margin-left: 0px;
      }
      .multiselectProduct {
        width: 180px;
      }
      .select {
        min-width: 180px;
        max-width: 180px;
        margin-left: 0px;
        height: 40px;
      }
      .lastColInput {
        margin-left: 0px;
      }
      .input_box{
        width: 180px;
      }
    }

    .fees {
      .hideCol {
        display: none;
      }
    }
    .pipelineGroup {
      margin-left: 120px;
    }

    .textComments {
      width: 90%;
    }

    .descriptionInputBox {
      height: unset;
    }
    
    .input_box {
      width: 180px;
    }
  }

  @media screen and (max-width: 580px) {
    .newTariff {
      .datepicker__holder {
        width: 180px;
      }
      .productType {
        margin-left: 18px;
        margin-right: 2px;
      }
      .product {
        margin-left: 20px;
      }
      .uom {
        margin-left: 20px;
      }
      .currency {
        margin-left: 0px;
      }
      .currencyLabel {
        margin-right: 0px;
      }
      .expanded_text_field {
        width: 180px;
      }
      .multiselect {
        width: 180px;
        margin-left: 0px;
      }
      .operator {
        width: 180px;
        margin-left: 0px;
      }
      .destination {
        width: 180px;
        margin-left: 0px;
      }
      .multiselectProduct {
        width: 180px;
      }
      .select {
        min-width: 180px;
        max-width: 180px;
        margin-left: 0px;
        height: 40px;
      }
      .lastColInput {
        margin-left: 0px;
      }
      .input_box{
        width: 180px;
      }
    }

    .fees {
      .secondaryRate {
        display: none;
      }
    }

    .textAreaDescription {
      height: 95px;
      width: 255px;
    }

    .rightFlex {
      right: 5px;
      top: 0px;
      margin-top: 0px;
    }

    .textComments {
      width: 70%;
    }

    .expanded_select {
      min-width: 180px;
      max-width: 180px;
    }

    .pipelineGroup {
      margin-left: 0px;
    }

    .descriptionInputBox {
      height: unset;
    }
    .input_box {
      width: 180px;
    }
  }
}
