.data-grid {
    > div:first-child {
        height: 80vh !important;
    }
}
.ag-cell-wrapper.ag-row-group {
    align-items: center !important; 
}

.page-size-div {
    background-color: #ffffff;
    box-shadow: 0 1px 0 0 #e8e8e8;
    font-size: 12px;
    color: #333333;
    // justify-content: flex-end;
    height: 40px; 
    // align-items: center;
    text-align: center;
    margin-top: 3px;
    margin-right: -15px;
}


div[id ='Setting Maintenance'] {
    > div:first-child {
        max-height: calc(100vh - 280px) !important;
    }
}

div[id ='Agency Maintenance'] {
    > div:first-child {
        max-height: calc(100vh - 280px) !important;
    }
}

div[id ='Location Maintenance'] {
    > div:first-child {
        max-height: calc(100vh - 280px) !important;
    }
}

div[id ='Connection Point Maintenance'] {
    > div:first-child {
        max-height: calc(100vh - 300px) !important;
    }
}
div[id ='Grade Maintenance'] {
    > div:first-child {
        max-height: calc(100vh - 280px) !important;
    }
}
div[id ='Filing Entity Maintenance'] {
    > div:first-child {
        max-height: calc(100vh - 280px) !important;
    }
}
div[id ='Filing Carrier Maintenance'] {
    > div:first-child {
        max-height: calc(100vh - 280px) !important;
    }
}
div[id ='Line Segment Maintenance'] {
    > div:first-child {
        max-height: calc(100vh - 310px) !important;
    }
}
div[id ='Profit Center Maintenance'] {
    > div:first-child {
        max-height: calc(100vh - 310px) !important;
    }
}

div[id ='Project Dashboard'] {
    > div:first-child {
        max-height: calc(100vh - 310px) !important;
    }
}

div[id ='Tariff Dashboard'] {
    > div:first-child {
        max-height: calc(100vh - 310px) !important;
    }
}

div[id ='Home Screen Dashboard'] {
    > div:first-child {
        max-height: calc(100vh - 310px) !important;
    }
}
div[id ='AddConnectionPointDetails'] {
    > div:first-child {
        max-height: calc(100vh - 400px) !important;
    }
}


.ag-header-cell.text-center {
    .ag-header-cell-label {
      justify-content: center;
    }
}
