.loader {
    &__icon {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 20;
    }
    &__overlay {
        position:fixed;
        top:0;
        right:0;
        bottom:0;
        left:0; 
        background: rgba(87, 68, 68, 0.3);
        z-index: 10;
    }
}