.locationMaintenanceBox {
    .search_text_field {
        background-color: #ffffff;
        color: #000000;
        font-size: 14px;
        height: 35px;
        min-width: 180px;
        max-width: 220px;
        border-radius: 5px;
        border: 1px solid   #a3d0e4;
        font-family: 'Arial';
        margin-left: 10px;
    }

    .input_text_field {
        background-color: #ffffff;
        color: #000000;
        font-size: 14px;
        height: 35px;
        min-width: 180px;
        max-width: 220px;
        border-radius: 5px;
        border: 1px solid #a3d0e4;
        font-family: 'Arial';
        margin: 10px;
    }

    .selectSecondLineContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .addNewAgencyButtonContainer {
        margin-left : 70%;
    }

    .search_criteria_title {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #002960 !important;
        letter-spacing: 0.4px;
        margin-left: 10%;
    }

    .rightFlex{
        display: flex;
        justify-content: flex-end;
        right: 157px;
        position: relative;
        top: 34px;
        margin-top: -35px;
    }

    .rotatedText {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        border: 1px solid #a3d0e4;
        padding: 10px;
        margin-right: 20px;
        text-align: center;
        color: #333333;
    }
    .pipelineLinkerBox {
        display: flex;
        background-color: #ffffff;
        box-shadow: 0 1px 0 0 #e8e8e8;
        margin-top: 10px;
        border: 1px solid #a3d0e4;
        border-bottom: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        justify-content: center;

        .data-grid {
            > div:first-child {
                height: 50vh !important;
                width: 100%
            }
        }
    }

    .screen_title {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #333333 !important;
        letter-spacing: 0.1px;
        line-height: 40px;
        margin-left: 10px;
    }

    .standard_label_style {
        color:#333333 !important;
        font-weight: bold;
        margin-left: 20px;
        font-size: 14px;
    }

    .boxes {
        display: flex;
        flex-direction: column;
        // background-color: #ffffff;
        // box-shadow: 0 1px 0 0 #e8e8e8;
        // border: 1px solid rgba(151, 151, 151, 0.23);
        border-bottom: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .selectContainer {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        border: 1px solid #a3d0e4;
        margin-bottom: 10px;
    }

    .settingSelectionDiv {
        margin-top: 10px;
    }

    .searchButtonDiv {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }

    .otherHeaderButtonsDiv {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-right: 10px;
    }

    .screenHeader {
        color: #333333;
        font-weight: bold;
        background-color: #d9ecf5;
        display: flex;
        flex-direction: row;
        height: 40px;
        border: 1px solid #a3d0e4;
        align-items: center;
    }

    .select {
        background-color: #ffffff;
        color: #000000;
        font-size: 14px;
        height: 35px;
        min-width: 180px;
        max-width: 220px;
        border-radius: 5px;
        border: 1px solid  #a3d0e4;
        font-family: 'Arial';
        margin-left: 5px;
        appearance: none;
        background-image: linear-gradient(50deg, transparent 50%,  #333333 50%),
                        linear-gradient(130deg,  #333333 50%, transparent 50%),
                        linear-gradient(to right, transparent, transparent);
        background-position:
            calc(100% - 15px) calc(1em + 1px),
            calc(100% - 10px) calc(1em + 1px),
            100% 0;
        background-size:
            5px 5px,
            5px 5px,
            2.5em 2.5em;
        background-repeat: no-repeat;
    }

    .onsearch {
        margin-left: 20px;
    }

    .onclear {
        margin-left: 20px;
    }

    .onAddNewSetting {
        margin-left: 20px;
    

    }
    .delete{

        margin-left:20px;
        margin-bottom: 20px;
    }


    .onsave {
        margin-left: 20px;
    }

    .ondelete {
        margin-left: 20px;
    
    }

    .deleteconfirm {
        margin-left:572px;
        margin-top:20px;

        position: relative;
        top: -52px;

    }

    button[disabled] {
        background-color: #CCC;
        margin-left: 20px;
    }
}