.datePickerGridInput{
    width: 100%
}
    
.AddPipelineBox {
    overflow-x: hidden;
    .ag-theme-balham input {
        background-color: rgb(220, 240, 255);
        border-width: 2px;
    }

label{
    // width: 8rem;
    margin-left: 0px;
    //width: 140px;
    width: 115px;
    line-height: 30px;
}

.wrapper{
    top:100px;
}
.textAreaPipelineArea{
    // height: 95px;
    // width: 390px;
    // max-width: 1000px;
    // margin-right: 20px;
    height: 95px;
//    width: 428px;
    max-width: 1000px;
    margin-right: 10px;
    //width: 400px;
    width: 400px;

}

.innerBoxes {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 1px 0 0 #e8e8e8;
    border: 1px solid rgba(151, 151, 151, 0.23);
    border-bottom: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 10px;
  }

input[disabled] {
    color: #DCDAD1;
}

textArea[disabled] {
    color: #DCDAD1;
}

.input{
    position: relative;
    // width: 163px !important;
    width: 230px;
}

.baseSystem {
    .input{
        margin-left: 0px;
        right: 120px;
        top: 30px;
        margin-bottom: 25px;
        // width: 300px
        width: 337px;
    }
    margin-right: -118px;
    .col{
        margin-right: -100px;
    }
}
.dates{
    label{
        width: unset;
    }
    align-self: center;
    .colWidth{
        //min-width: 315px;
        // min-width: 426px;
        min-width: 383px;
    }
    margin-top: -10px;
}

.flexDetails {
    display: inline-block;
    border: 1px solid black;
    //margin-top: 20px;
    margin: 10px;
    margin-top: -10px;
    flex-wrap: wrap
}
.ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
    background-color: red;
}


.inner {
    width: 50%;
    padding: 20px;
    display: inline-flex;
}

.rotatedText {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    border: 1px solid black;
    padding: 10px;
    margin-right: 20px;
    text-align: center;
}

.datepicker__holder {
    width: 251px;
}

.select {
    // // max-width: 200px;
    // width: 177px !important;
    // margin-left: 0px;
    margin-left: 10px;
    // width: 256px !important;
    // width: 315px !important;
    width: 70% !important;
    max-width: unset;
}

// .rightFlexSave{
//     // position: relative;
//     // top: -13px;
//     // position: relative;
//     // top: -52px;
//     // align-items: flex-end;
//     // justify-content: flex-end;
//     // margin-right: 10px;
//     // margin-bottom: -35px;
    
// }
.rightFlexSave {
    position: relative;
    top: -52px;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: -35px;
    width: 200px;
    float: right;
}



.status-block{
    display: inline-flex;
    // label{
    //     width:50px
    // }
}

input:read-only {
    background: lightgray !important;
}

textArea[disabled] {
    color: #DCDAD1;
}


textArea:read-only {
    background: lightgray;
}

select[disabled] {
    background: lightgray;
}

input[type=radio] {
        position: relative;
        margin-top: 5px;
        margin-bottom: 25px;
}

.col{
    display: flex;
    margin-top: 10px;
    // max-width: 325px;
    //max-width: 317px;
    margin-left: -5px;
}

.colPipelineArea{
    max-width: unset;
}


.rightFlexGrid{
    display: flex;
    justify-content: flex-end;
    right: 157px;
    position: relative;
    top: 34px;
    margin-top: -35px;
}

.rightFlex{
    display: flex;
    justify-content: flex-end;
    // right: 20px;
    right: 10px;
    position: relative
    // position: relative;
    // top: 34px;
    // margin-top: -35px;
}


.rightFlexBottom{
    display: flex;
    justify-content: flex-end;
    right: 10px;
    position: relative;
  //  margin-top: -25px;
}

.textComments{
    width: 92%;
    max-width: unset;
    margin-right: 20px;
}

@media screen and (max-width: 1106px) {
   .inner {
    width:500px;
   }
   .rightFlexSave{
     top: -15px;
     margin-bottom: 5px;
   }
   .baseSystem{
    .input{
        width: 222px !important
    }
    }
}
@media screen and (max-width: 580px) {
 
    .textAreaPipelineArea {
        height: 95px;
        width: 283px;
    }
    .rightFlex{
        right: 5px;
        top: 0px;
        margin-top: 0px;
    }
    .textComments {
        width: 70%;
    }
    .inner {
        .select{
            // width:70% !important;
            width: 288px;
            min-width: unset;
        }
    } 
    .baseSystem{
        .input{
            width: 111px !important
        }
    }
}
// .row{
//     margin-top:20px;
// }





    .row{
        margin-left: 5px;
        margin-right: 5px;
    }

    .data-grid {
        > div:first-child {
            height: 37vh !important;
            margin-right: 5px
        }
    }

    .physicalPipeline{
        select{
            width: 200px !important;
        }
    }

    .relatedAreas{
        .data-grid {
            > div:first-child {
                height: 28vh !important;
                margin-right: 5px
            }
        }
    }
    

    .page-size-div {
        border: none;
    }

    .labelClass {
        margin-left: 5px; 
        margin-top: 10px;
        font-weight: bold;
        label {
            width:unset;
        }
    }
    
    .selectContainer {
        padding-bottom: 5px;
    }
}