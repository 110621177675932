.pmcGridViewBox {
  .data-grid {
    > div:first-child {
      height: 50vh !important;
      width: 100%;
      margin-right: 20px;
    }
  } 

  .modal-dialog {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 44%;
  }

  .modal-body {
    overflow-y: auto;
  }

  .screen_title {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #333333 !important;
    letter-spacing: 0.1px;
    line-height: 40px;
    margin-left: 10px;
  }

  .search_text_field {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 38px;
    min-width: 60px;
    max-width: 100px;
    border-radius: 5px;
    border: 1px solid #a3d0e4;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 0px 8px 0px 4px;
  }

  .standard_label_style {
    color: #333333 !important;
    font-weight: bold;
    // margin: 0px 20px;
    font-size: 14px;
    width: 132px;
  }

  .update_standard_label_style {
    color: #333333 !important;
    font-weight: bold;
    // margin: 0px 20px;
    font-size: 14px;
    width: 84px;
  }

  .small_standard_label_style {
    color: #333333 !important;
    font-weight: bold;
    // margin: 0px 20px;
    font-size: 14px;
    width: 28px;
  }

  .updateTariff {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    position: relative;
    right: 20px;
  }

  .duplicateTariff {
    display: flex;
    justify-content: start;
    margin-top: 8px;
    position: relative;
    left: 20px;
  }

  .updateLabel {
    // margin-right: -36px;
    margin-top: 6px;
  }

  .updateTariffButton {
    display: flex;
    justify-content: end;
    margin-top: 8px;
    position: relative;
    right: 20px;
  }

  .datepicker__holder {
    border: 1px solid #a3d0e4;
    width: 202px;
  }

  .effectiveEndDate {
    margin-left: -144px;
  }

  .systemGroup {
    margin-right: 8px;
  }

  .boxes {
    display: flex;
    flex-direction: column;
    // background-color: #ffffff;
    // box-shadow: 0 1px 0 0 #e8e8e8;
    // border: 1px solid rgba(151, 151, 151, 0.23);
    border-bottom: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .dateCol {
    display: flex;
    margin-top: 4px;
  }

  .dateLabel {
    margin-top: 4px;
  }

  .settingSelectionDiv {
    margin-top: 16px;
    display: flex;
    .data-grid {
      > div:first-child {
        height: 50vh !important;
        width: 100%;
      }
    }
  }

  label {
    align-self: center;
  }

  .col {
    display: flex;
    margin-top: 10px;
  }

  .searchButtonDiv {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  .screenHeader {
    color: #333333;
    font-weight: bold;
    background-color: #d9ecf5;
    display: flex;
    flex-direction: row;
    height: 40px;
    border: 1px solid #a3d0e4;
    align-items: center;
  }
  .select {
    height: 40px;
  }

  .select_small {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 40px;
    width: 140px;
    border-radius: 5px;
    border: 1px solid #a3d0e4;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin-left: 5px;
    appearance: none;
    background-image: linear-gradient(50deg, transparent 50%, #333333 50%),
      linear-gradient(130deg, #333333 50%, transparent 50%),
      linear-gradient(to right, transparent, transparent);
    background-position: calc(100% - 15px) calc(1em + 1px), calc(100% - 10px) calc(1em + 1px),
      100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
  }

  .expanded_select {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 40px;
    width: 344px;
    border-radius: 5px;
    border: 1px solid #a3d0e4;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    appearance: none;
    background-image: linear-gradient(50deg, transparent 50%, #333333 50%),
      linear-gradient(130deg, #333333 50%, transparent 50%),
      linear-gradient(to right, transparent, transparent);
    background-position: calc(100% - 15px) calc(1em + 1px), calc(100% - 10px) calc(1em + 1px),
      100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
    margin-right: 12px;
  }

  button[disabled] {
    background-color: #ccc;
    margin-left: 20px;
  }

  @media screen and (max-width: 1223px) {
    .expanded_select {
      min-width: 180px;
      max-width: 180px;
    }
  }

  @media screen and (max-width: 580px) {
    .expanded_select {
      min-width: 180px;
      max-width: 180px;
    }
    // .effectiveEndDate {
    //   margin-left: 0px;
    // }
    .standard_label_style {
      color: #333333 !important;
      font-weight: bold;
      margin: 0px 25px;
      font-size: 14px;
      width: 0px;
    }
  }

  .flex {
    display: flex;
  }

  .rotatedText {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    border: 1px solid black;
    padding: 10px;
    margin-right: 20px;
    text-align: center;
  }

}
