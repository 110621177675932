.ProjectRequestBox{
    label{
        min-width: 150px;
        line-height: 2;
    }
    .inline{
        display: inline-flex;
        width: 100%;
    }
    .search_text_field{
        min-width: 333px !important;
        height: 40px;
        
    }
    // .col{
    //     min-width: 37%;
    // }
}

.ProjectRequestDetailsBox{
    
    .search_text_field{
        // min-width: 333px !important;
        height: 40px;
        
    }
    .textarea{
        border-radius: 5px;
        border: 1px solid #a3d0e4;
    }
    .select {
        background-color: #ffffff;
        color: #000000;
        font-size: 14px;
        height: 40px;
        width: 344px;
        border-radius: 5px;
        border: 1px solid #a3d0e4;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        appearance: none;
        background-image: linear-gradient(50deg, transparent 50%, #333333 50%),
          linear-gradient(130deg, #333333 50%, transparent 50%),
          linear-gradient(to right, transparent, transparent);
        background-position: calc(100% - 15px) calc(1em + 1px), calc(100% - 10px) calc(1em + 1px),
          100% 0;
        background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
        background-repeat: no-repeat;
        margin-right: 12px;
      }
    .multiselect {
        border-radius: 5px;
        border: 1px solid #a3d0e4;
    }
    // .col{
    //     min-width: 37%;
    // }
}