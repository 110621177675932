.CreateBaseTariffBox {

.settingSelectionDiv{
    display: flex;
    flex-direction: row;
}
    
label{
    // width: 8rem;
    // margin-left: 0px;
    width: 103px;
    min-width: 103px;
}

.textAreaPipelineArea {
    height: 95px;
    max-width: unset;
    width: 70%;
    margin-left: 5px;
    margin-bottom: 20px;
}

input[disabled] {
    color: #DCDAD1;
}

textArea[disabled] {
    color: #DCDAD1;
}

.input{
    // position: relative;
    width: 163px !important;
    margin-left: 5px;
}
.select {
    // // max-width: 200px;
    // width: 177px !important;
    // margin-left: 0px;
    
    width: 177px !important;
    // margin-left: 10px;

}

.status-block{
    display: inline-flex;
    margin-left: -10px;
}

input:read-only, select:disabled {
    background: lightgray;
    color:#000000;
}

textArea[disabled] {
    color:#000000;
}


textArea:read-only {
    background: lightgray;
}

input[type=radio] {
        position: relative;
        margin-top: 5px;
        margin-bottom: 25px;
}

.col{
    display: flex;
    margin-top: 20px;
    min-width: 340px;
    max-width: 340px;
}

.colPipelineArea{
    // max-width: 40%; 
    display: flex;
    flex-direction: row;
    max-width: 42%;
    margin-top: 20px;
}

.colComments{
    // max-width: 40%; 
    margin-top: 20px;
    display: inline-flex;
}


.rightFlex{
    display: flex;
    justify-content: flex-end;
    right: 157px;
    position: relative;
    top: 34px;
    margin-top: -35px;
}

.rightFlexBottom{
    display: flex;
    justify-content: flex-end;
    right: 5px;
    position: relative;
  //  margin-top: -25px;
}

// .textComments{
//     width: 52.5%;
//     max-width: unset;
//     margin-right: 20px;
// }

.textComments {
    width: 99%;
    max-width: unset;
    margin-right: 20px;
}

.margin20{
    margin-top:20px;
}

@media screen and (max-width: 1223px) {
    .status-block{
        display: inline-flex;
        label{
            width:103px
        }
    }
}
@media screen and (max-width: 380px) {
    .col,.colComments, .colPipelineArea{
        display: inline-grid;
    }
    .colPipelineArea{
        // max-width: 40%; 
        margin-left: 5px;
    }
    .custom-button{
        height: 50px;
    }
    .search_text_field {
        min-width: 170px;
        max-width: 170px;
    }
    .textComments {
        margin-left: 10px;
    }
}
    .data-grid {
        > div:first-child {
            height: 50vh !important;
            min-height: 200px !important;
            // margin-right: 5px
        }
    }

    // .page-size-div {
    //     border: none;
    // }
    
}