.datepicker {
    display: flex;
    &__holder {
        padding-left: 6px;
        box-sizing: border-box;
        height: 35px;
        width: 180px;
        border: 1px solid #9B9B9B;
        border-radius: 4px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        line-height: 12px;
        margin-left: 10px;
        color: #000000;
        font-size: 14px;
        height: 35px;
    }
}