.react-datetime-picker__wrapper {
    border: 1px solid rgba(151,151,151,0.23) !important;
}

.react-datepicker {
    min-width: max-content !important;
}

.react-datepicker-popper {
    z-index: 2;
}