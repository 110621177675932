.cbasetariff
{
margin-left: 20px;
}

.selects
{
    margin-right:20px;
}

.select {
    margin-left: 20px;
    width: 100px;
}

.cctslog
{
    margin-left:20px;
}

.ctariffrequest
{
    margin-left:20px;  
}

.agencycode
{
    margin-left: 20px;   
}

.clear
{
    margin-left: 20px;
}

.search {
    margin-left: 330px;
}

.newagency
{
    margin-bottom: 40px;
    margin-right: -721px;
}

.agency{
    margin-left: 50px;
}

.all
{
    margin-left: 20px;
}


.box {
    height: 600;
    width: 1530px;
    
    padding: 10px;
    border: solid 3px black;
    margin: 0
}

.alls
{
    margin-left:20px;
}
.agencymaintenance{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.state
{
    margin-left: -110px;
}

.search_text_field {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 35px;
    min-width: 180px;
    max-width: 220px;
    border-radius: 5px;
    border: 1px solid   #a3d0e4;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;;
    margin-left: 10px;
}

.selectSecondLineContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.addNewAgencyButtonContainer {
    margin-left : 70%;
}

.search_criteria_title {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #333333 !important;
    letter-spacing: 0.4px;
    margin-left: 10%;
}

.screen_title {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #333333 !important;
    letter-spacing: 0.1px;
    line-height: 40px;
    margin-left: 10px;
}

.standard_label_style {
    color: #333333 !important;
    font-weight: bold;
    margin-left: 20px;
    font-size: 14px;
}

label {
    align-self: center;
}

.boxes {
    display: flex;
    flex-direction: column;
    // background-color: #ffffff;
    // box-shadow: 0 1px 0 0 #e8e8e8;
    // border: 1px solid rgba(151, 151, 151, 0.23);
    border-bottom: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.selectContainer {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    border: 1px solid #a3d0e4;
    margin-bottom: 10px;
}

.settingSelectionDiv {
    margin-top: 10px;
    display: flex;
}

.searchButtonDiv {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.otherHeaderButtonsDiv {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-right: 10px;
}

.screenHeader {
    color: #333333;
    font-weight: bold;
    background-color: #d9ecf5;
    display: flex;
    flex-direction: row;
    height: 40px;
    border: 1px solid #a3d0e4;
    align-items: center;
}

.select {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 35px;
    min-width: 180px;
    max-width: 220px;
    border-radius: 5px;
    border: 1px solid  #a3d0e4;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;;
    margin-left: 5px;
    appearance: none;
    background-image: linear-gradient(50deg, transparent 50%,  #333333 50%),
                    linear-gradient(130deg,  #333333 50%, transparent 50%),
                    linear-gradient(to right, transparent, transparent);
    background-position:
        calc(100% - 15px) calc(1em + 1px),
        calc(100% - 10px) calc(1em + 1px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
}

.onsearch {
    margin-left: 20px;
}

.onclear {
    margin-left: 20px;
}

.onAddNewSetting {
    margin-left: 20px;


}
.delete{

    margin-left:20px;
    margin-bottom: 20px;
}


.onsave {
    margin-left: 20px;
}

.ondelete {
    margin-left: 20px;

}

.deleteconfirm {
    margin-left:572px;
    margin-top:20px;

    position: relative;
    top: -52px;

}

input:read-only,
input[disabled],
select[disabled],
button[disabled] {
    background: lightgray;
}

button[disabled] {
    background-color: #CCC;
    margin-left: 20px;
}
