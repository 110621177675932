.connectionPointMaintenanceView {
    
.screen_title {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #333333 !important;
    letter-spacing: 0.1px;
    line-height: 40px;
    margin-left: 10px;
}

.standard_label_style {
    color: #333333 !important;
    font-weight: bold;
    margin-left: 20px;
    font-size: 14px;
}

label{
    // width: 8rem;
    // margin-left: 0px;
    width: 120px;
}

.search_text_field {
    margin-left: 5px;
    width: 300px !important;
}

.input{
    // position: relative;
    width: 163px !important;
}

input:read-only,input[disabled],select[disabled], button[disabled] {
    background: lightgray;
}

.select {
    // // max-width: 200px;
    // width: 177px !important;
    // margin-left: 0px;
    height: 40px;
    width: 300px !important;
    // margin-left: 10px;

}

.boxes {
    display: flex;
    flex-direction: column;
    // background-color: #ffffff;
    // box-shadow: 0 1px 0 0 #e8e8e8;
    // border: 1px solid rgba(151, 151, 151, 0.23);
    border-bottom: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.selectContainer {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    border: 1px solid #a3d0e4;
    margin-bottom: 10px;
}

.settingSelectionDiv {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.searchButtonDiv {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.otherHeaderButtonsDiv {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-right: 10px;
}

.screenHeader {
    color: #333333;
    font-weight: bold;
    background-color: #d9ecf5;
    display: flex;
    flex-direction: row;
    height: 40px;
    border: 1px solid #a3d0e4;
    align-items: center;
}

.agGridView {
    display: flex;
    align-items: center;
}

.select {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 40px;
    min-width: 180px;
    max-width: 220px;
    border-radius: 5px;
    border: 1px solid  #a3d0e4;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;;
    margin-left: 5px;
    appearance: none;
    background-image: linear-gradient(50deg, transparent 50%,  #333333 50%),
                    linear-gradient(130deg,  #333333 50%, transparent 50%),
                    linear-gradient(to right, transparent, transparent);
    background-position:
        calc(100% - 15px) calc(1em + 1px),
        calc(100% - 10px) calc(1em + 1px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
}

.onsearch {
    margin-left: 20px;
}

.onclear {
    margin-left: 20px;
}

.onAddNewSetting {
    margin-left: 20px;


}
.delete{

    margin-left:20px;
    margin-bottom: 20px;
}


.onsave {
    margin-left: 20px;
}

.ondelete {
    margin-left: 20px;

}

.deleteconfirm {
    margin-left:572px;
    margin-top:20px;

    position: relative;
    top: -52px;

}

button[disabled] {
    background-color: #CCC;
    margin-left: 20px;
}
.grid-cell-bgColor{
    &-yellow {
        background-color: #ffd900 !important;
    }
    &-red {
        background-color: #ff0033 !important;
    }
    &-blue {
        background-color: lightskyblue !important;
    }
    &-green {
        background-color: rgb(61, 206, 61) !important;;
    }
}

.row {
    margin: 10px;
}

.col {
    display: flex;
    max-width: unset;
    align-items: center;
}

}