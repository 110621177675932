.kn-column__column___3Iwzx {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-right: 1px solid #dddddd; }
    .kn-column__column___3Iwzx:last-of-type {
      border: 0; }
  
  .kn-list__list___22Wuc {
    outline: none;
    user-select: none; }
  
  .kn-list__list_item___1mDV9 {
    display: block;
    flex-direction: column; }
  
  .kn-list__scrollbar___1a9W-::-webkit-scrollbar, .kn-list__list___22Wuc::-webkit-scrollbar {
    width: 15px;
    height: 17px; }
  
  .kn-list__scrollbar___1a9W-::-webkit-scrollbar-thumb, .kn-list__list___22Wuc::-webkit-scrollbar-thumb {
    background: #CDCDCD;
    border-color: transparent;
    border-style: solid;
    background-clip: content-box; }
  
  .kn-list__scrollbar___1a9W-::-webkit-scrollbar-thumb:vertical, .kn-list__list___22Wuc::-webkit-scrollbar-thumb:vertical {
    min-height: 45px;
    border-width: 4px 4px 4px 5px; }
  
  .kn-list__scrollbar___1a9W-::-webkit-scrollbar-thumb:horizontal, .kn-list__list___22Wuc::-webkit-scrollbar-thumb:horizontal {
    min-width: 45px;
    border-width: 5px 4px 6px; }
  
  .kn-list__scrollbar___1a9W-::-webkit-scrollbar-track, .kn-list__list___22Wuc::-webkit-scrollbar-track {
    background: #ffffff; }
  
  .kn-list__scrollbar___1a9W-::-webkit-scrollbar-track:vertical, .kn-list__list___22Wuc::-webkit-scrollbar-track:vertical {
    border-left: 1px solid #EDEDED;
    border-right: none; }
  
  .kn-list__scrollbar___1a9W-::-webkit-scrollbar-track:horizontal, .kn-list__list___22Wuc::-webkit-scrollbar-track:horizontal, .kn-list__scrollbar___1a9W-::-webkit-scrollbar-corner, .kn-list__list___22Wuc::-webkit-scrollbar-corner {
    border-top: 1px solid #EDEDED;
    border-bottom: none; }
  
  .kn-item_label__label___2fJ5p {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 14px;
    white-space: nowrap; }
  
  .kn-item__item___kQ2Ll {
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    cursor: pointer; }
    .kn-item__item___kQ2Ll .kn-item__selected___3tRL6 {
      background-color: #f7f7f7; }
    .kn-item__item___kQ2Ll:hover {
      background-color: #f3f3f3; }
  
  .kn-item__disabled___3xX7z {
    color: #b4b4b4;
    cursor: default; }
  
  .kn-item__selected___3tRL6 {
    background-color: #f7f7f7; }
  
  .kn-item__with_border___3n9sw {
    border-bottom: 1px solid #dddddd; }
  
  .kn-item__with_grouping___3IJz8 {
    color: #000000;
    padding-left: 10px; }
  
  .kn-no_items__no_items___3ON4R {
    font-size: 14px;
    color: #999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; }
  
  .kn-search__search___3Zpb2 {
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 12px 0 0;
    border-bottom: 1px solid #dddddd; }
  
  .kn-search__input___2tyxf {
    height: 45px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    flex: 1;
    outline: none;
    border: 0;
    font-size: 14px;
    background: transparent; }
  
  .kn-search__icon___1frx4 {
    font-size: 24px; }
  
  .kn-search__list_filter_container___2FaR9 {
    margin-bottom: 10px;
    position: relative; }
  
  .kn-selected_item__selected_item___1uiiv, .kn-selected_item__with_grouping___1Ibnd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 12px;
    margin-right: -4px;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer; }
    .kn-selected_item__selected_item___1uiiv:hover, .kn-selected_item__with_grouping___1Ibnd:hover {
      background-color: #f3f3f3; }
  
  .kn-selected_item__with_grouping___1Ibnd {
    pointer-events: none;
    color: #000000; }
  
  .kn-selected_item__disabled___1430r {
    color: #b4b4b4;
    background-color: #f7f7f7;
    cursor: default; }
  
  .kn-selection_status__selection_status___1FzyX {
    height: 45px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dddddd;
    justify-content: space-between; }
  
  .kn-selection_status__status___1qxE9 {
    color: #000000; }
  
  .kn-selection_status__clear_all___3gppf {
    cursor: pointer;
    color: #008BBF; }
  
  .kn-multi_select__wrapper___30BEc {
    display: flex;
    flex-direction: row;
    position: relative;
    background: #ffffff;
    border: 1px solid #dddddd;
    color: #666666;
    overflow: hidden; }
  
  .kn-loader__loader___1LPsU {
    margin: auto auto; }
  
  