.serviceDateInputStyle {
    // padding-left: 10px;
    min-width:200px;
    height: 40px;
    border: 0px;
    background-color: #F5F5F5 !important;
    color: #000000;	

    &-editable {
        min-width:200px;
        height: 40px;
        border: 0px;
        background-color: #DCF0FF !important;
    }

    &-D {
        padding-left: 10px;
        min-width:200px;
        height: 40px;
        color: #000000;
        border: 0px;	
        background-color: #ff0033 !important;
    }
}
