.defaultpage {
    &__defaultpage {
        display: flex;
        flex-direction: column;
        width: 320px;
        align-items: center;
    }
    &__parent_container {
        box-sizing: border-box;
        flex: 1;
        height: 85vh;
        border: 1px solid rgba(151, 151, 151, 0.23);
        border-radius: 6px;
        background-color: #ffffff;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__nodatafound {
        font-size: 17px;
        line-height: 20px;
        height: 19px;
        width: 144px;
        text-align: center;
        font-weight: bold;
        margin-top: 47px;
        color: #002960;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    &__selectTypeMessage {
        margin-top: 16px;
        color: rgba(0, 41, 96, 0.4);
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
        line-height: 17px;
    }
    &__defaulticon {
        height: 191px;
        width: 179px;
    }
}
