.lineSegmentMaintenanceBox {
    .screen_title {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #333333 !important;
        letter-spacing: 0.1px;
        line-height: 40px;
        margin-left: 10px;
    }

    .standard_label_style {
        color: #333333 !important;
        font-weight: bold;
        margin-left: 20px;
        font-size: 14px;
    }

    label{
        // width: 8rem;
        // margin-left: 0px;
        align-self: center;
        width: 170px !important;
    }

    .search_text_field {
        margin-left: 5px;
        width: 300px !important;
    }

    .expanded_search_text_field {
        background-color: #ffffff;
        color: #000000;
        font-size: 14px;
        height: 35px;
        width: 392px;
        border-radius: 5px;
        border: 1px solid #a3d0e4;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        margin-left: 8px;
        margin-top: 10px;
    }

    .input{
        // position: relative;
        width: 163px !important;
    }
    .select {
        // // max-width: 200px;
        // width: 177px !important;
        // margin-left: 0px;
        
        width: 300px !important;
        // margin-left: 10px;

    }

    .expandselect {
        max-width: 300px !important;
    }

    .boxes {
        display: flex;
        flex-direction: column;
        // background-color: #ffffff;
        // box-shadow: 0 1px 0 0 #e8e8e8;
        // border: 1px solid rgba(151, 151, 151, 0.23);
        border-bottom: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .selectContainer {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        border: 1px solid #a3d0e4;
        margin-bottom: 10px;
    }

    .settingSelectionDiv {
        margin: 10px 0px;
        display: flex;
        flex-direction: row;
    }

    .searchButtonDiv {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }

    .otherHeaderButtonsDiv {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-right: 10px;
    }

    .expandedInput {
        width: 202px !important;
    }

    .flex {
        display: flex;
    }

    .col {
        display: flex;
        margin-top: 10px;
    }

    .remarksBox {
        display: flex;
        background-color: #ffffff;
        box-shadow: 0 1px 0 0 #e8e8e8;
        // margin-top: 10px;
        border: 1px solid #a3d0e4;
        border-bottom: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        justify-content: center;

        .data-grid {
            >div:first-child {
                height: 50vh !important;
                width: 100%
            }
        }
    }

    .screenHeader {
        color: #333333;
        font-weight: bold;
        background-color: #d9ecf5;
        display: flex;
        flex-direction: row;
        height: 40px;
        border: 1px solid #a3d0e4;
        align-items: center;
    }

    .agGridView {
        display: flex;
        align-items: center;
    }

    .select {
        background-color: #ffffff;
        color: #000000;
        font-size: 14px;
        height: 40px;
        min-width: 180px;
        max-width: 220px;
        border-radius: 5px;
        border: 1px solid  #a3d0e4;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;;
        margin-left: 5px;
        appearance: none;
        background-image: linear-gradient(50deg, transparent 50%,  #333333 50%),
                        linear-gradient(130deg,  #333333 50%, transparent 50%),
                        linear-gradient(to right, transparent, transparent);
        background-position:
            calc(100% - 15px) calc(1em + 1px),
            calc(100% - 10px) calc(1em + 1px),
            100% 0;
        background-size:
            5px 5px,
            5px 5px,
            2.5em 2.5em;
        background-repeat: no-repeat;
    }

    .onsearch {
        margin-left: 20px;
    }

    .onclear {
        margin-left: 20px;
    }

    .onAddNewSetting {
        margin-left: 20px;


    }
    .delete{

        margin-left:20px;
        margin-bottom: 20px;
    }


    .onsave {
        margin-left: 20px;
    }

    .ondelete {
        margin-left: 20px;

    }

    .deleteconfirm {
        margin-left:572px;
        margin-top:20px;

        position: relative;
        top: -52px;

    }

    input:read-only,
    input[disabled],
    select[disabled],
    button[disabled] {
        background: lightgray;
    }

    button[disabled] {
        background-color: #CCC;
        margin-left: 20px;
    }
    .grid-cell-bgColor{
        &-yellow {
            background-color: #ffd900 !important;
        }
        &-red {
            background-color: #ff0033 !important;
        }
        &-blue {
            background-color: lightskyblue !important;
        }
        &-green {
            background-color: rgb(61, 206, 61) !important;;
        }
    }

    .row {
        margin: 0px 10px 10px 10px;
    }
    .custom-button {
        height: 40px;
    }

    .datepicker__holder {
        margin-left: 6px;
    }

    @media screen and (max-width: 1710px) {
        .select {
            min-width: 180px;
            max-width: 180px;
            margin-left: 0px;
            height: 40px;
        }
        .col {
            width: 32%;
        }
        .custom-button {
            height: 50px;
            margin-right: 10px;
        }
        .search_text_field {
            min-width: 180px;
            max-width: 180px;
            margin-left: 0px;
        }
        .datepicker__holder {
            margin-left: 0px;
        }
    }

    @media screen and (max-width: 1223px) {
        .select {
            min-width: 180px;
            max-width: 180px;
            margin-left: 0px;
            height: 40px;
        }
        .col {
            width: 32%;
        }
        .custom-button {
            height: 50px;
            margin-right: 10px;
        }
        .search_text_field {
            min-width: 180px;
            max-width: 180px;
            margin-left: 0px;
        }
        .datepicker__holder {
            margin-left: 0px;
        }
    }

    @media screen and (max-width: 380px) {
        .select {
            min-width: 180px;
            max-width: 180px;
            margin-left: 0px;
            height: 40px;
        }
    
        .col {
            width: 32%;
        }
    
        .custom-button {
            height: 50px;
            margin-right: 10px;
        }
        .search_text_field {
            min-width: 180px;
            max-width: 180px;
            margin-left: 0px;
        }
        .datepicker__holder {
            margin-left: 0px;
        }
    }

    @media screen and (max-width: 580px) {
        .select {
            min-width: 180px;
            max-width: 180px;
            margin-left: 0px;
            height: 40px;
        }
        .col{
            width: 32%;
        }
        .custom-button {
            height: 50px;
            margin-right: 10px;
        }
        .search_text_field {
            min-width: 180px;
            max-width: 180px;
            margin-left: 0px;
        }
        .datepicker__holder {
            margin-left: 0px;
        }
    }
    }