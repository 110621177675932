.custom-button {
    width: auto;
    border-radius: 3px;	
    box-sizing: border-box;
    background-color: #FFFFFF;
    height: 30px;
    align-items: center;
    margin-top:20px;
    margin-right: 10px;;
    min-width: 80px;
    font-family: "Arial";
    font-size: 14px;
    justify-content: center;
    line-height: 14px;
    text-align: center;
    &:hover {
        background-color: #69DDFC
    }
    &:disabled{
        background-color: #d3d3d3;
        border: 1px solid #d3d3d3;
        margin-left: 10px;
    }
    &-cancel {
        border: 1px solid black;
    }
    &-submit {
        margin: 10px 0px 10px 10px;
        // background-color: #00B1F6;
        background-color: #d9ecf5;
        border-radius: 5px;
        border: 1px solid  #a3d0e4;
    }
    &-ok {
        background-color: #d9ecf5;
        border-radius: 5px;
        border: 1px solid  #a3d0e4;
        margin-left: 10px;
    }
    &-register {
        border: 1px solid #0b2d71;
        &:hover {
            background-color: #FFFFFF;
        }
        width: 160px;
        border-radius: 4px;
        height: 38px;
        margin: 0px;
    }
    &__label {
        &-cancel {
            color: #4A4A4A;	
        }
        &-submit {
            color: #333333;
        }
        &-ok {
            color: #333333;
        }
        &-register {
            color: #0b2d71;
        }
    }
    &__icon {
        margin-top: -3px;
        margin-right: 6px;
        &-normal {
            color: #003263;
        }
    }
}