.select {
    width: 100%;
}
.css-1wa3eu0-placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.css-1okebmr-indicatorSeparator {
    display: none !important;
}

.css-tlfecz-indicatorContainer {
    color: #4a4a4a !important;
}

.css-26l3qy-menu {
    z-index: 2 !important;
}

.css-1uccc91-singleValue {
    color: #042a6e !important;
}
.css-yk16xz-control {
    border: 1.2px solid #002960 !important;
    border-radius: 4px !important;
}
