.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
    flex-direction: column;
    &__primary {
        margin-top: 30px;
        color: #003263;	
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;	
        font-size: 40px;	
        line-height: 15px;
    }
    &__secondary {
        margin-top: 30px;
        color: #808081;	
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;	
        font-size: 24px;	
        line-height: 15px;
    }
}