
.custom-btn {
    width: 160px;	
    border-radius: 4px;	
    background-color: #3389D2;	
    box-shadow: 0 3px 13px 3px rgba(155,155,155,0.06);
}
$accordion-button-active-bg:  #d9ecf5;
$accordion-button-active-color:  #333333 !important;

.accordion-button {
    font-size : 16px;
    font-weight: bold;
    color: #333333 !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;;
}

$border-color:#a3d0e4;

@import "bootstrap/scss/bootstrap";