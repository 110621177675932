.ProcessOptionBoxStep2 {
  .screenHeaderTabs {
    color: #333333;
    font-weight: bold;
    background-color: #d9ecf5;
    height: unset;
    min-height: 40px;
    border: 1px solid #a3d0e4;
  }

  
  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    z-index: 10000;
    -webkit-animation: spin 0.85s linear infinite; /* Safari */
    animation: spin 0.85s linear infinite;
    position: fixed;
    top: 43%;
    left: 46.35%;
    transform: translate(-50%, -50%);
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  label {
    line-height: 2;
    vertical-align: top;
    min-width: 116px;
    margin-left: 20px;
  }
  // .inline{
  //     // width: 100%;
  //     // display: inline-flex;
  // }
  .multiSelectContainer {
    width: 100%;
    height: 200%;
    margin-left: 10px;
  }
  .multiselect {
    width: 100%;
    min-width: 169px;
  }

  .mainContainerMultiselect {
    div:nth-child(2) {
      width: 100%;
    }
  }

  textArea {
    width: 95%;
    height: 100px;
    margin-left: 10px;
  }

  .nav-tabs .nav-item {
    color: #333333;
    font-weight: bold;
    padding: 5px;
    height: 100%;
    button {
      color: #333333;
      font-weight: 600;
      font-size: 18px;
    }
  }

  .search_text_field {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    // width: 90%;
    height: 40px;
    min-width: 258px;
    max-width: unset;
    border-radius: 5px;
    border: 1px solid #a3d0e4;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin-left: 10px;
    margin-right: 10px;
    width: 58%;
  }

  .reviewTabSelectContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid #a3d0e4;
    margin-bottom: 10px;
    padding: 4px;
  }

  .tariffValue {
    color: #333333 !important;
    font-size: 14px;
    margin-top: 3px;
    margin-left: 8px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .tariffValueChanged {
    color: red !important;
    font-size: 14px;
    margin-top: 3px;
    margin-left: 8px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .expanded_select {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    height: 40px;
    width: 344px;
    border-radius: 5px;
    border: 1px solid #a3d0e4;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    appearance: none;
    background-image: linear-gradient(50deg, transparent 50%, #333333 50%),
      linear-gradient(130deg, #333333 50%, transparent 50%),
      linear-gradient(to right, transparent, transparent);
    background-position: calc(100% - 15px) calc(1em + 1px), calc(100% - 10px) calc(1em + 1px),
      100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
    margin-right: 12px;
  }

  input:read-only,
  textArea:read-only,
  select:disabled {
    background: lightgray !important;
  }
  

  .data-grid {
    > div:first-child {
      height: 50vh !important;
      width: 98%;
      min-height: 200px;
      min-width: 170px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .rightFlex {
    width: 50%;
    display: flex;
  }

  .tariffCol {
    display: flex;
    margin-top: 10px;
  }

  .tariffReview {
    display: flex;
  }

  .standard_label_style_tariff {
    color: #333333 !important;
    font-weight: bold;
    margin-left: 20px;
    font-size: 14px;
    min-width: 176px;
  }

  .standard_label_style_tariff_changed {
    color: red !important;
    font-weight: bold;
    margin-left: 20px;
    font-size: 14px;
    min-width: 176px;
  }

  .standard_label_style_review_header {
    color: #333333 !important;
    font-weight: bold;
    margin-left: 20px;
    font-size: 15px;
    min-width: 176px;
    text-decoration: underline;
  }

  .rightFlexInner {
    justify-content: flex-end;
    display: flex;
    width: 100%;
  }

  .leftFlex {
    justify-content: flex-start;
    display: flex;
    margin-top: 10px;
    label {
      min-width: 120px;
    }
  }

  .rightFlexButtons {
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    display: flex;
    align-items: flex-end;
    // margin-left: 10px;
    margin-left: -10px;
  }

  .flex {
    display: flex;
  }

  .flexColumn {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .innerflexwidths {
    width: 50%;
  }

  .datepicker {
    display: inline-flex;
    width: 80%;
  }

  .status-block {
    display: inline-flex;
  }

  input[type='radio'] {
    margin: 0px 10px 0px 10px;
  }

  @media screen and (max-width: 980px) {
    .flex {
      flex-direction: column;
    }

    .custom-button {
      margin-right: 10px;
    }

    .innerflexwidths {
      width: 100%;
    }

    button {
      height: 50px;
    }

    .rightFlex {
      button {
        margin-right: 5px;
      }
    }

    textArea {
      width: 92%;
    }

    .rightFlex {
      width: 100%;
    }

    .rightFlexButtons {
      margin-left: 10px;
      padding-right: 5px;
    }

    .data-grid {
      > div:first-child {
        width: 98%;
      }
    }
  }
  @media screen and (max-width: 580px) {
    .data-grid {
      > div:first-child {
        width: 92%;
      }
    }

    textArea {
      width: 80%;
      margin-left: 0px;
    }

    .rightFlex {
      flex-wrap: wrap;
    }
  }
}
