.confirm {
   width: 350px;
   background-color: #FFFFFF;	
   box-shadow: 0 0 10px 0 #9B9B9B;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 15px;
   max-height: 500px;
   overflow-y: auto;
   &-warning {
       color: #D0021B;
   }
   &__warning {
       margin-top: 10px;
   }
   &__primary-text {
        color: #4A4A4A;	
        display: flex;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;	
        margin-top: 10px;
        font-size: 18px;	
        font-weight: bold;	
        line-height: 17px;	
   }
   &__secondary-text {
        color: #606060;	
        display: flex;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;	
        margin-top: 10px;
        font-size: 14px;	
        line-height: 17px;
   }
   &__action {
       margin-top: 15px;
       margin-bottom: 15px;
       display: flex;
   }
   &__actionLoc {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    margin-left: 10px;
}
}
.checker {
   width: 300px;
   background-color: #ffffff;
   flex-direction: column;
   padding: 7px;
   max-height: 300px;
}
.alertBox {
  position: fixed;
  z-index: 1; 
  padding-top: 10px;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto;
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
}
.error-message {
    background-color: #ffffff;
    margin-top: 0;
    margin-left: 30%;
    width: 650px;
    height: auto;
    overflow-y: auto;
    padding: 10px;
    padding-bottom: 0;
    
}
.react-confirm-alert-overlay {
    background: none;
}

.custom-confirm {
    background-color: #FFFFFF;	
    box-shadow: 0 0 10px 0 #9B9B9B;
    display: flex;
    padding: 15px;
}

.invalid-value {
    border: 1px solid red !important;
}
.email-error{
    display: block;
        font-size: 10px;
        color: red;
}
.email{
    width: 500px;
   background-color: #FFFFFF;	
   box-shadow: 0 0 10px 0 #9B9B9B;
   display: flex;
   flex-direction: column;
   padding: 20px;
   max-height: 500px;
   overflow-y: auto;
}
.loadpopup{
    width: 590px;
   background-color: #FFFFFF;   
   box-shadow: 0 0 10px 0 #9B9B9B;
   display: flex;
   flex-direction: column;
   padding: 20px;
   height: 500px;
   overflow-y: auto;
}

.newloadpopup{
    width: 615px;
   background-color: #FFFFFF;   
   box-shadow: 0 0 10px 0 #9B9B9B;
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: 15px;
   height: 400px;
   overflow-y: auto;
}

.spanWidth30{
    width: 100px;
}
.location {
   width: 400px;
   background-color: #FFFFFF;	
   box-shadow: 0 0 10px 0 #9B9B9B;
   display: flex;
   flex-direction: column;
   padding: 20px;
   max-height: 1000px;
   min-height: 250px;
   overflow-y: auto;
   min-width: 225px;
&__consignee {
    min-width: 350px;
    padding-right: 10px;
    padding-top: 10px;
    width: auto;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    flex-direction: column;
    text-decoration-color: red;
    }
}
.roleactive{
    color: white;
    background-color: rgb(19, 106, 206);
    border: 0.2px solid grey;
    margin-top: 5px;
}
.roleInactive{
    color: black;
    border: 0.2px solid grey;
    margin-top: 5px;
}

.InputFromTo{
    margin : 10px 0px;
}
.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .InputFromTo .DayPickerInput-Overlay {
    width: 350px;
  }
  .DayPickerInput input {
    height: 35px import !important;
  }



.location__maping {
    width: 600px;
    background-color: #FFFFFF;	
    box-shadow: 0 0 10px 0 #9B9B9B;
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-height: 2000px;
    overflow-y: auto;
    min-width: 225px;
 &__text {
     min-width: 350px;
     padding-right: 10px;
     padding-top: 0px;
     padding-bottom: 10px;
     width: auto;
     font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
     border-radius: 4px;
     font-size: 14px;
     line-height: 18px;
     flex-direction: row;
     text-decoration-color: red;
 }
 }

 .infoVideo{
    width: 400px;
   background-color: #FFFFFF;	
   box-shadow: 0 0 10px 0 #9B9B9B;
   display: flex;
   border: 1px solid #9B9B9B;
   padding: 20px;
   max-height: 500px;
   overflow-y: auto;
   &-warning {
    color: #D0021B;
   }
   &__warning {
    margin-right: 10px;
    float:right;
   }
   &__labelFont {
       font-size: 16px;
   }
}
.alert-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(87, 68, 68, 0.3);
    z-index: 10;
}
.dots-1 {
    position: fixed;
    top: 50%;
    left: 47%;
    transform: translate(-50%, -50%);
    z-index: 20;
    width: 4px;
    height: 4px;
    padding: 3px;
    color:green;
    border-radius: 50%;
    box-shadow: 19px 0 0 6px, 49px 0 0 3px, 79px 0 0 0;
    animation: d1 .6s infinite alternate linear;
  }
  
  @keyframes d1 {
    50%  {box-shadow: 19px 0 0 3px, 49px 0 0 6px, 79px 0 0 3px}
    100% {box-shadow: 19px 0 0 0  , 49px 0 0 3px, 79px 0 0 6px}
  }
  