.TariffOptionsBox {
    overflow-x: hidden;
    .internalHeader {
         background-color: #eeeeee; 
         border: #333333; 
         margin-top: 10px; 
         padding: 5px 5px 5px 5px;
    }
    .internalHeader:hover {
         background-color: #d6d6d6; 
         border: #d6d6d6; 
         margin-top: 10px; 
         padding: 5px 5px 5px 5px;
     }
 
     .screenHeaderTabs {
         font-weight: bold;
         height: unset;
         min-height: 40px;
         border: 1px solid #a3d0e4;
         margin-top:10px;
         display:inline-flex;
         flex-wrap:wrap;
     }
 
     .screenHeaderTab {
         color: #333333;
         background-color: #d9ecf5;
         border: 1px solid #a3d0e4;
         min-width: 200px;
         text-align: center;
         padding: 5px;
         min-width: 300px;
     }
 
     .screenHeaderTab:hover {
         // background-color: #C5E0F0;
         background-color: #B1D6EA;
     }
 
     .screenheaderTabActive{
         color: #333333;
         border: 1px solid #a3d0e4;
         // background-color: white;
         background-color: #FFFFFF;
         min-width: 300px;
         text-align: center;
         padding: 5px;
     }
     .screenheaderTabYellow{
         color: #333333;
         border: 1px solid #a3d0e4;
         // background-color: white;
         background-color: yellow;
         min-width: 300px;
         text-align: center;
         padding: 5px;
     }
 
     
     .screenheaderTabRed{
         color: #333333;
         border: 1px solid #a3d0e4;
         // background-color: white;
         background-color: red;
         min-width: 300px;
         text-align: center;
         padding: 5px;
     }
 
     
     .screenHeaderTabSelected {
         color: #333333;
         background-color: #C5E0F0;
         // background-color: #EDF6FA;
         border: 1px solid #a3d0e4;
         min-width: 300px;
         text-align: center;
         padding: 5px;
     }
     
     .screenHeaderTabSelected:hover {
         background-color: #B1D6EA;
 
     }
 
 
     .screenheaderTabActive:hover {
         background-color: #B1D6EA;
     }
     
     .commentExtSytem{
         width: 97%;
         height:  95px;
         margin-right: 10px;
     }
 
     .pipelineAreas{
         width: 63%;
         height: 78.5px;
     }
 
     .descriptionTariffLogArea{
         width: 63%;
         height: 95px;
         margin-bottom: 10px;
     }
 
 
    .search_text_field {
         margin-left: 0px;
         min-width: 225px !important;
         max-width: 225px !important; 
         vertical-align: top;
         display: inline-grid;
         height: 40px;
     }
 
     .select{
         margin-left: 0px;
         min-width: 225px;
         max-width: unset; 
         vertical-align: top;
         width: 60%;
         height: 40px;
     }
 
     .datepicker__holder {
         border: 1px solid #a3d0e4;
         width: unset;
     }
 
     .datepicker {
         display: inline-flex;
         vertical-align: top;
     }
 
     .rotatedText {
         writing-mode: tb-rl;
         transform: rotate(-180deg);
         border: 1px solid black;
         padding: 10px;
         margin-right: 20px;
         text-align: center;
     }
     .flex {
         display: flex;
     }
     // .screen_title {
     //     margin-left: 10px;
     // }
     // .selectContainer {
     //     justify-content: left;
     // }
     .predictiveSelect {
         display: block;
         padding: 5px;
         border: 1px solid black;
     }
     .predictiveSelectContainer{
         background: lightblue;
         position: absolute;
         z-index: 1;
         height: 143px;
         overflow-y: scroll;
         width: 180px;
     }
     .data-grid {
         > div:first-child {
             height: unset !important;
             width: 100%;
         }
         .ag-center-cols-clipper {
             min-height: 100px !important;
         }
         margin-top: 10px;
     }    
     .page-size-div {
         border: none;
     }
 label{
     margin-left: 0px;
     // width: 106px;
     // width: 95px;
     width: 140px;
     //width:125px
     line-height: 2.5;
 }
 
 .textAreaPipelineArea{
     height: 95px;
     max-width: 1000px;
     margin-right: 10px;
     //width: 325px;
     margin-left: 5px;
     width: 300px;
 }
 
 
 input[disabled], button[disabled] {
     border: 1px solid #d3d3d3;
 }
 
 input:read-only,input[disabled],select[disabled], button[disabled] {
     background: lightgray;
 }
 
 textArea:read-only {
     background: lightgray;
 }
 
 // .select {
 //     // // max-width: 200px;
 //     // width: 177px !important;
 //     // margin-left: 0px;
     
 //     width: 177px !important;
 //     margin-left: 10px;
 
 // }
 
 .status-block{
     display: inline-flex;
     width: 45%;
     margin-left: -10px;
 }
 
 input[type=radio] {
         position: relative;
         margin-top: 5px;
         margin-bottom: 25px;
 }
 
 .col {
     flex: 1 0;
     margin-right: -38px;
     margin-left: 0px;
 }
 
 .tariffTeam{
     .col {
         max-width: unset;
     }
     label{
         width: 137px;
     }
     .descriptionInputBox{
         // width: 70% !important;
         height: 200%;
         max-width: unset;
     }
 }
 
 .colPipelineArea{
     max-width: 464px;;
 }
 
 .attachmentsGrid {
     display: inline-flex;
 }
 
 
 .rightFlex{
     display: inline-flex;
     flex-wrap: wrap;
     justify-content: flex-end;
     align-items: flex-end;
     // right: 20px;
     right: 10px;
     margin-top:10px;
     position: relative;
     // position: relative;
     // top: 34px;
     // margin-top: -35px;
 }
 
 .rightFlex2{
     display: inline-flex;
     flex-wrap: wrap;
     justify-content: flex-end;
     align-items: flex-end;
     // right: 20px;
     right: 10px;
     margin-top:10px;
     position: relative;
     width: fit-content;
     // position: relative;
     // top: 34px;
     // margin-top: -35px;
 }
 
 .LeftFlex{
     display: inline-flex;
     flex-wrap: wrap;
     justify-content: flex-start;
     // right: 20px;
     margin-left: 10px;
     margin-top:10px;
     position: relative;
     width: fit-content;
     // position: relative;
     // top: 34px;
     // margin-top: -35px;
 }
 
 .textComments{
     width: 92%;
     max-width: unset;
     margin-right: 20px;
 }
 
 @media screen and (max-width: 1223px) {
     // .status-block{
     //     display: inline-flex;
     //     label{
     //         width:103px
     //     }
     // }
     .tariffTeam{
         .descriptionInputBox{
             height: unset;
         }
     }
     .textComments {
         width: 90%;
     }
 }
 @media screen and (max-width: 580px) {
     .inputLast{
         max-width: unset;
         width: 225px;
     }
     .rightFlex{
         right: 5px;
         top: 0px;
         margin-top: 0px;
     }
 }
 // .row{
 //     margin-top:20px;
 // }
 
     // .row{
     //     margin-left: 5px;
     // }
     .versionInput{
         background-color: #ffffff;
         color: #000000;
         font-size: 14px;
         height: 35px;
         width: 50.5px;
         border-radius: 5px;
         border: 1px solid   #a3d0e4;
         font-family: 'Arial';
         margin-left: 2.5px;
     }
     .mainVersionInput{
         background-color: #ffffff;
         color: #000000;
         font-size: 14px;
         height: 35px;
         width: 100px;
         border-radius: 5px;
         border: 1px solid   #a3d0e4;
         font-family: 'Arial';
         margin-left: 0px;
     }
     .versionInputTariff{
        background-color: #ffffff;
        color: #000000;
        font-size: 14px;
        height: 35px;
        width: 50.5px;
        border-radius: 5px;
        border: 1px solid   #a3d0e4;
        font-family: 'Arial';
        margin-left: 2.5px;
    }
     .mainVersionInputTariff{
         background-color: #ffffff;
         color: #000000;
         font-size: 14px;
         height: 35px;
         width: 66px;
         border-radius: 5px;
         border: 1px solid   #a3d0e4;
         font-family: 'Arial';
         margin-left: 0px;
     }
     .inputLast{
         max-width: unset !important;
         width: 60%;
         display: inline-grid;
     }

 }