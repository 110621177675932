.ag-theme-balham {
    .ag-root {
        border: 1px solid  #a3d0e4;;
        background-color: #FFFFFF;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        
    }
    .ag-root-wrapper {
        border: 1px solid  #a3d0e4;;        
    }
    // .ag-rich-select {
    //     background-color: #FFFFFF;
    //     .ag-rich-select-row {
    //         color: #4A4A4A;
    //         font-family: Gotham;
    //         font-size: 12px;
    //         font-weight: 500;
    //         line-height: 14px;
    //     }
    // }
    .ag-popup-editor {
        border: 1px solid rgba(151, 151, 151, 0.23);
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    }
    .ag-row-focus {
        z-index: 0;
    }
    .ag-header {
        background-color: #DCDCDC;
        box-shadow: 0 1px 0 0 #DCDCDC;
        border-bottom: 1px solid rgba(151, 151, 151, 0.23);
        border-left: 0px;
        border-right: 0px;
        border-top: 0px;
        height: 40px !important;
        min-height: 40px !important;
        .ag-header-cell,
        .checkbox-container__label {
            color: #4A4A4A;
            font-family: "Arial";
            font-size: 12px;
            line-height: 15px;
        }
        .ag-header-cell-label .ag-header-cell-text,
        .checkbox-container__label {
            color: #4A4A4A;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 12px;
            font-weight: bold;
            line-height: 15px;
        }
        .ag-header-row {
            height: 40px !important;
        }
    }
    .ag-status-bar {
        background: none;
        height: 30px;
    }
    .ag-row {
        height: 40px !important;
        .ag-cell {
            font-family: "Arial";
            color: black;
            font-size: 11px;
            line-height: 14px;
            background-color: #F5F5F5;
        }
        .ag-cell-value {
            display: flex;
            align-items: center;
            font-family: "Arial";
            color: black;
            font-size: 11px;
            font-weight: 500;
            line-height: 14px;
            background-color: #F5F5F5;
        }
    }
    .ag-row-even {
        background-color: white;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);
    }
    .ag-cell.ag-cell-inline-editing {
        height: 40px;
    }
    .ag-row-odd {
        background-color: rgb(220, 240, 255);
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);
        input {
            background-color: rgb(220, 240, 255);
        }
    }
    .ag-body-viewport-wrapper.ag-layout-normal {
        overflow-x: scroll;
        overflow-y: scroll;
    }
     ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 8px;
    }
     ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
    .ag-paging-button {
        border: 1px solid #DCDCDC;
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: inset 0 -1px 0 0 #CCCCCC, 0 8px 24px 0 rgba(0, 0, 0, 0.2);
        padding: 3px;
        margin: 0px 3px;
    }
    .ag-paging-row-summary-panel {
        color: #4A4A4A;
        font-family: "Arial";
        font-size: 12px;
        font-weight: 500;
    }
    .ag-paging-page-summary-panel {
        color: #4A4A4A;
        font-family: "Arial";
        font-size: 12px;
        font-weight: 500;
    }
    .ag-paging-panel {
        border-top: 0px;
        height: 40px;
        justify-content: flex-start;
        margin-top: -30px;
        z-index: 1;
        width: 80%;
        position: relative;
    }
    .ag-row-hover {
        background-color: #EBF5FF;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);
        input {
            background-color: #EBF5FF;
        }
    }
    label {
        margin-bottom: 0;
        margin-left: 10px;
        color: #4A4A4A;
        font-family: "Arial";
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
    }
    .cell-editable {
        background-color: #DCF0FF !important;
    }
    .cell-overridden {
        background-color: yellow !important;
        color: red !important;
    }
    .cell-overridden-yellow {
        background-color: yellow !important;
    }
    .cell-deleted {
        background-color: red !important;
    }
    .cell-new {
        background-color: lightgreen !important;
    }
    .cell-overridden-editable{
        background-color: yellow !important;
        border: 5px solid lightgrey !important;
        color: red !important; 
    }
    .cell-disabled {
        background-color: lightgrey !important;
    }
    .cell-volume {
        background-color: rgb(255, 181, 91) !important;
    }
    .cell-red {
        background-color: rgb(255, 0, 0) !important;
    }
    .ag-react-container {
        width: 100% ;
    }
    .ag-virtual-list-container {
        overflow: auto hidden !important;
    }
    .ag-set-filter-item-value {
        overflow: initial !important;
        text-overflow: unset !important;
        white-space: nowrap !important;
    }
}